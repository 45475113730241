import React, { FC, useState, useEffect } from 'react';
import { Invoice, ProductLine } from '../data/types';
import { initialInvoice, initialProductLine } from '../data/initialData';
import EditableInput from './EditableInput';
import EditableTextarea from './EditableTextarea';
import EditableCalendarInput from './EditableCalendarInput';
import EditableFileImage from './EditableFileImage';
import Document from './Document';
import Page from './Page';
import View from './View';
import Text from './Text';
import { Font } from '@react-pdf/renderer';
import Download from './DownloadPDF';
import format from 'date-fns/format';
import RobotoRegular from '../fonts/Roboto-Regular.ttf';
import RobotoBold from '../fonts/Roboto-Bold.ttf';

Font.register({
	family: 'Roboto',
	fonts: [{ src: RobotoRegular }, { src: RobotoBold, fontWeight: 600 }],
});

interface Props {
	data?: Invoice;
	pdfMode?: boolean;
	onChange?: (invoice: Invoice) => void;
}

const InvoicePage: FC<Props> = ({ data, pdfMode, onChange }) => {
	const [invoice, setInvoice] = useState<Invoice>(data ? { ...data } : { ...initialInvoice });
	const [subTotal, setSubTotal] = useState<number>();
	const [saleTax, setSaleTax] = useState<number>();

	const dateFormat = 'MMM dd, yyyy';
	const invoiceDate = invoice.invoiceDate !== '' ? new Date(invoice.invoiceDate) : new Date();
	const invoiceDueDate = invoice.invoiceDueDate !== '' ? new Date(invoice.invoiceDueDate) : new Date(invoiceDate.valueOf());

	if (invoice.invoiceDueDate === '') {
		invoiceDueDate.setDate(invoiceDueDate.getDate() + 30);
	}

	const handleChange = (name: keyof Invoice, value: string | number) => {
		if (name !== 'productLines') {
			const newInvoice = { ...invoice };

			if ((name === 'logoWidth' || name === 'footerWidth' || name === 'qrCodeWidth') && typeof value === 'number') {
				newInvoice[name] = value;
			} else if (name !== 'logoWidth' && name !== 'footerWidth' && name !== 'qrCodeWidth' && typeof value === 'string') {
				newInvoice[name] = value;
			}

			setInvoice(newInvoice);
		}
	};

	const handleProductLineChange = (index: number, name: keyof ProductLine, value: string) => {
		const productLines = invoice.productLines.map((productLine, i) => {
			if (i === index) {
				const newProductLine = { ...productLine };

				if (name === 'description') {
					newProductLine[name] = value;
				} else {
					if (value[value.length - 1] === '.' || (value[value.length - 1] === '0' && value.includes('.'))) {
						newProductLine[name] = value;
					} else {
						const n = parseFloat(value);

						newProductLine[name] = (n ? n : 0).toString();
					}
				}

				return newProductLine;
			}

			return { ...productLine };
		});

		setInvoice({ ...invoice, productLines });
	};

	const handleRemove = (i: number) => {
		const productLines = invoice.productLines.filter((productLine, index) => index !== i);

		setInvoice({ ...invoice, productLines });
	};

	const handleAdd = () => {
		const productLines = [...invoice.productLines, { ...initialProductLine }];

		setInvoice({ ...invoice, productLines });
	};

	const calculateAmount = (quantity: string, rate: string) => {
		const quantityNumber = parseFloat(quantity);
		const rateNumber = parseFloat(rate);
		const amount = quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;

		return amount.toFixed(2);
	};

	useEffect(() => {
		let subTotal = 0;

		invoice.productLines.forEach((productLine) => {
			const quantityNumber = parseFloat(productLine.quantity);
			const rateNumber = parseFloat(productLine.rate);
			const amount = quantityNumber && rateNumber ? quantityNumber * rateNumber : 0;

			subTotal += amount;
		});

		setSubTotal(subTotal);
	}, [invoice.productLines]);

	useEffect(() => {
		const match = invoice.taxLabel.match(/(\d+)%/);
		const taxRate = match ? parseFloat(match[1]) : 0;
		const saleTax = subTotal ? (subTotal * taxRate) / 100 : 0;

		setSaleTax(saleTax);
	}, [subTotal, invoice.taxLabel]);

	useEffect(() => {
		if (onChange) {
			onChange(invoice);
		}
	}, [onChange, invoice]);

	return (
		<Document pdfMode={pdfMode}>
			<Page className="invoice-wrapper" pdfMode={pdfMode}>
				{!pdfMode && <Download data={invoice} />}

				<View className="flex" pdfMode={pdfMode}>
					<View className="w-50" pdfMode={pdfMode}>
						<EditableFileImage className="logo" placeholder="Your Logo" value={invoice.logo} width={invoice.logoWidth} pdfMode={pdfMode} onChangeImage={(value) => handleChange('logo', value)} onChangeWidth={(value) => handleChange('logoWidth', value)} />
						<EditableInput className="fs-10 padding-bt-0 mt-10 bold" placeholder="Εταιρεία" value={invoice.companyName} onChange={(value) => handleChange('companyName', value)} pdfMode={pdfMode} />
						<EditableInput className="fs-10 padding-bt-0" placeholder="Όνομα" value={invoice.name} onChange={(value) => handleChange('name', value)} pdfMode={pdfMode} />
						<EditableInput className="fs-10 padding-bt-0" placeholder="Διεύθυνση" value={invoice.companyAddress} onChange={(value) => handleChange('companyAddress', value)} pdfMode={pdfMode} />
						<EditableInput className="fs-10 padding-bt-0" placeholder="Πόλη, ΤΚ" value={invoice.companyAddress2} onChange={(value) => handleChange('companyAddress2', value)} pdfMode={pdfMode} />
						<EditableInput className="fs-10 padding-bt-0" placeholder="Χώρα" value={invoice.companyCountry} onChange={(value) => handleChange('companyCountry', value)} pdfMode={pdfMode} />
					</View>
					<View className="w-50" pdfMode={pdfMode}>
						<EditableInput className="fs-45 right bold" placeholder="Invoice" value={invoice.title} onChange={(value) => handleChange('title', value)} pdfMode={pdfMode} />

						<EditableFileImage className="w-100 margin-auto" width={invoice.qrCodeWidth} placeholder="QR Code" value={invoice.qrCode} pdfMode={pdfMode} onChangeImage={(value) => handleChange('qrCode', value)} onChangeWidth={(value) => handleChange('qrCodeWidth', value)} />
					</View>
				</View>

				<View className="flex mt-30" pdfMode={pdfMode}>
					<View className="w-55" pdfMode={pdfMode}>
						<EditableInput className="bold dark mb-5 fs-12 padding-bt-0" value={invoice.billTo} onChange={(value) => handleChange('billTo', value)} pdfMode={pdfMode} />
						<EditableInput className="fs-12 padding-bt-0" placeholder="Όνομα Πελάτη" value={invoice.clientName} onChange={(value) => handleChange('clientName', value)} pdfMode={pdfMode} />
						<EditableInput className="fs-12 padding-bt-0" placeholder="Διεύθυνση" value={invoice.clientAddress} onChange={(value) => handleChange('clientAddress', value)} pdfMode={pdfMode} />
						<EditableInput className="fs-12 padding-bt-0" placeholder="Πόλη, ΤΚ" value={invoice.clientAddress2} onChange={(value) => handleChange('clientAddress2', value)} pdfMode={pdfMode} />
						<EditableInput className="fs-12 padding-bt-0" placeholder="Χώρα" value={invoice.clientCountry} onChange={(value) => handleChange('clientCountry', value)} pdfMode={pdfMode} />
					</View>
					<View className="w-45" pdfMode={pdfMode}>
						<View className="flex mb-5" pdfMode={pdfMode}>
							<View className="w-40" pdfMode={pdfMode}>
								<EditableInput className="bold" value={invoice.invoiceDueDateLabel} onChange={(value) => handleChange('invoiceDueDateLabel', value)} pdfMode={pdfMode} />
							</View>
							<View className="w-60" pdfMode={pdfMode}>
								<EditableInput value={invoice.invoiceDueDate} onChange={(value) => handleChange('invoiceDueDate', value)} pdfMode={pdfMode} />
							</View>
						</View>
						<View className="flex mb-5" pdfMode={pdfMode}>
							<View className="w-40" pdfMode={pdfMode}>
								<EditableInput className="bold" value={invoice.invoiceDateLabel} onChange={(value) => handleChange('invoiceDateLabel', value)} pdfMode={pdfMode} />
							</View>
							<View className="w-60" pdfMode={pdfMode}>
								<EditableCalendarInput value={format(invoiceDate, dateFormat)} selected={invoiceDate} onChange={(date) => handleChange('invoiceDate', date && !Array.isArray(date) ? format(date, dateFormat) : '')} pdfMode={pdfMode} />
							</View>
						</View>
						<View className="flex mb-5" pdfMode={pdfMode}>
							<View className="w-40" pdfMode={pdfMode}>
								<EditableInput className="bold" value={invoice.invoiceTitleLabel} onChange={(value) => handleChange('invoiceTitleLabel', value)} pdfMode={pdfMode} />
							</View>
							<View className="w-60" pdfMode={pdfMode}>
								<EditableInput placeholder="INV-12" value={invoice.invoiceTitle} onChange={(value) => handleChange('invoiceTitle', value)} pdfMode={pdfMode} />
							</View>
						</View>
					</View>
				</View>

				<View className="mt-30 bg-dark flex" pdfMode={pdfMode}>
					<View className="w-48 p-4-8" pdfMode={pdfMode}>
						<EditableInput className="white bold" value={invoice.productLineDescription} onChange={(value) => handleChange('productLineDescription', value)} pdfMode={pdfMode} />
					</View>
					<View className="w-17 p-4-8" pdfMode={pdfMode}>
						<EditableInput className="white bold right" value={invoice.productLineQuantity} onChange={(value) => handleChange('productLineQuantity', value)} pdfMode={pdfMode} />
					</View>
					<View className="w-17 p-4-8" pdfMode={pdfMode}>
						<EditableInput className="white bold right" value={invoice.productLineQuantityRate} onChange={(value) => handleChange('productLineQuantityRate', value)} pdfMode={pdfMode} />
					</View>
					<View className="w-18 p-4-8" pdfMode={pdfMode}>
						<EditableInput className="white bold right" value={invoice.productLineQuantityAmount} onChange={(value) => handleChange('productLineQuantityAmount', value)} pdfMode={pdfMode} />
					</View>
				</View>

				{invoice.productLines.map((productLine, i) => {
					return pdfMode && productLine.description === '' ? (
						<Text key={i}></Text>
					) : (
						<View key={i} className="row flex" pdfMode={pdfMode}>
							<View className="w-48" pdfMode={pdfMode}>
								<EditableTextarea className="dark fs-10" rows={2} placeholder="Όνομα/Περιγραφή Προϊόντος" value={productLine.description} onChange={(value) => handleProductLineChange(i, 'description', value)} pdfMode={pdfMode} />
							</View>
							<View className="w-17" pdfMode={pdfMode}>
								<EditableInput className="dark right fs-10" value={productLine.quantity} onChange={(value) => handleProductLineChange(i, 'quantity', value)} pdfMode={pdfMode} />
							</View>
							<View className="w-17" pdfMode={pdfMode}>
								<EditableInput className="dark right fs-10" value={productLine.rate} onChange={(value) => handleProductLineChange(i, 'rate', value)} pdfMode={pdfMode} />
							</View>
							<View className="w-18" pdfMode={pdfMode}>
								<Text className="dark right fs-10" pdfMode={pdfMode}>
									{calculateAmount(productLine.quantity, productLine.rate)}
								</Text>
							</View>
							{!pdfMode && (
								<button className="link row__remove" aria-label="Αφαίρεση" title="Αφαίρεση" onClick={() => handleRemove(i)}>
									<span className="icon icon-remove bg-red"></span>
								</button>
							)}
						</View>
					);
				})}
				<View className="w-50 mt-10 mb-20" pdfMode={pdfMode}>
					{!pdfMode && (
						<button className="link" onClick={handleAdd}>
							<span className="icon icon-add bg-green mr-10"></span>
							Προσθήκη Προϊόντος
						</button>
					)}
				</View>
				<View className="flex" pdfMode={pdfMode}>
					<View className="w-50 mt-20" pdfMode={pdfMode}>
						<View className="flex mb-5" pdfMode={pdfMode}>
							<View className="w-20" pdfMode={pdfMode}>
								<EditableInput placeholder="BANK" className="bold" value={invoice.bankLabel} onChange={(value) => handleChange('bankLabel', value)} pdfMode={pdfMode} />
							</View>
							<View className="w-80" pdfMode={pdfMode}>
								<EditableInput placeholder="bank" value={invoice.bank} onChange={(value) => handleChange('bank', value)} pdfMode={pdfMode} />
							</View>
						</View>
						<View className="flex mb-5" pdfMode={pdfMode}>
							<View className="w-20" pdfMode={pdfMode}>
								<EditableInput placeholder="IBAN" className="bold" value={invoice.ibanLabel} onChange={(value) => handleChange('ibanLabel', value)} pdfMode={pdfMode} />
							</View>
							<View className="w-80" pdfMode={pdfMode}>
								<EditableInput placeholder="iban" value={invoice.iban} onChange={(value) => handleChange('iban', value)} pdfMode={pdfMode} />
							</View>
						</View>
						<View className="flex mb-5" pdfMode={pdfMode}>
							<View className="w-30" pdfMode={pdfMode}>
								<EditableInput placeholder="BIC(SWIFT)" className="bold" value={invoice.bicLabel} onChange={(value) => handleChange('bicLabel', value)} pdfMode={pdfMode} />
							</View>
							<View className="w-70" pdfMode={pdfMode}>
								<EditableInput placeholder="bic" value={invoice.bic} onChange={(value) => handleChange('bic', value)} pdfMode={pdfMode} />
							</View>
						</View>
					</View>
					<View className="w-50 mt-20" pdfMode={pdfMode}>
						<View className="flex" pdfMode={pdfMode}>
							<View className="w-50 p-5" pdfMode={pdfMode}>
								<EditableInput value={invoice.subTotalLabel} onChange={(value) => handleChange('subTotalLabel', value)} pdfMode={pdfMode} />
							</View>
							<View className="w-50 p-5" pdfMode={pdfMode}>
								<Text className="right bold dark" pdfMode={pdfMode}>
									{subTotal?.toFixed(2)}
								</Text>
							</View>
						</View>
						<View className="flex" pdfMode={pdfMode}>
							<View className="w-50 p-5" pdfMode={pdfMode}>
								<EditableInput value={invoice.taxLabel} onChange={(value) => handleChange('taxLabel', value)} pdfMode={pdfMode} />
							</View>
							<View className="w-50 p-5" pdfMode={pdfMode}>
								<Text className="right bold dark" pdfMode={pdfMode}>
									{saleTax?.toFixed(2)}
								</Text>
							</View>
						</View>
						<View className="flex bg-gray p-5" pdfMode={pdfMode}>
							<View className="w-50 p-5" pdfMode={pdfMode}>
								<EditableInput className="bold" value={invoice.totalLabel} onChange={(value) => handleChange('totalLabel', value)} pdfMode={pdfMode} />
							</View>
							<View className="w-50 p-5 flex" pdfMode={pdfMode}>
								<EditableInput className="dark bold right ml-30" value={invoice.currency} onChange={(value) => handleChange('currency', value)} pdfMode={pdfMode} />
								<Text className="right bold dark w-auto" pdfMode={pdfMode}>
									{(typeof subTotal !== 'undefined' && typeof saleTax !== 'undefined' ? subTotal + saleTax : 0).toFixed(2)}
								</Text>
							</View>
						</View>
					</View>
				</View>

				<View className="mt-20" pdfMode={pdfMode}>
					<EditableInput className="fs-12 bold w-100" value={invoice.notesLabel} onChange={(value) => handleChange('notesLabel', value)} pdfMode={pdfMode} />
					<EditableTextarea className="fs-12 w-100" rows={2} value={invoice.notes} onChange={(value) => handleChange('notes', value)} pdfMode={pdfMode} />
				</View>

				<View className="w-100 mt-20" pdfMode={pdfMode}>
					<EditableFileImage className="footer w-100 margin-auto" width={invoice.footerWidth} placeholder="Your footer" value={invoice.footer} pdfMode={pdfMode} onChangeImage={(value) => handleChange('footer', value)} onChangeWidth={(value) => handleChange('footerWidth', value)} />
					<EditableTextarea className="w-100 center fs-10 " rows={2} value={invoice.footerText} onChange={(value) => handleChange('footerText', value)} pdfMode={pdfMode} />
				</View>
			</Page>
		</Document>
	);
};

export default InvoicePage;
