import React, { FC } from 'react';
import { Text } from '@react-pdf/renderer';
import DatePicker, { registerLocale } from 'react-datepicker';
import el from 'date-fns/locale/el';
import 'react-datepicker/dist/react-datepicker.css';
import compose from '../styles/compose';
import { format } from 'date-fns';

registerLocale('el', el);

interface Props {
	className?: string;
	value?: string;
	selected?: Date;
	onChange?: (date: Date | [Date, Date] | null) => void;
	pdfMode?: boolean;
}

const EditableCalendarInput: FC<Props> = ({ className, value, selected, onChange, pdfMode }) => {
	const formatDateInGreek = (date: string) => {
		return format(Date.parse(date), 'MMM dd, yyyy', { locale: el });
	};

	return <>{pdfMode ? <Text style={compose('span ' + (className ? className : ''))}>{formatDateInGreek(value!)}</Text> : <DatePicker locale="el" className={'input ' + (className ? className : '')} selected={selected} onChange={onChange ? (date) => onChange(date) : (date) => null} dateFormat="MMM dd, yyyy" />}</>;
};

export default EditableCalendarInput;
