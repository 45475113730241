import { ProductLine, Invoice } from './types';

export const initialProductLine: ProductLine = {
	description: '',
	quantity: '1',
	rate: '0.00',
};

export const initialInvoice: Invoice = {
	logo: '',
	logoWidth: 100,
	qrCode: '',
	qrCodeWidth: 100,
	footer: '',
	footerWidth: 500,
	title: 'ΤΙΜΟΛΟΓΙΟ',
	companyName: '',
	name: '',
	companyAddress: '',
	companyAddress2: '',
	companyCountry: 'Ελλάδα',
	billTo: 'Χρέωση σε:',
	clientName: '',
	clientAddress: '',
	clientAddress2: '',
	clientCountry: 'Ελλάδα',
	invoiceTitleLabel: 'Τιμολόγιο#',
	invoiceTitle: '',
	invoiceDateLabel: 'Ημ/νία Τιμολογίου',
	invoiceDate: '',
	invoiceDueDateLabel: 'Ημ/νία Λήξης',
	invoiceDueDate: '',
	productLineDescription: 'Περιγραφή Προϊόντος',
	productLineQuantity: 'Ποσότητα',
	productLineQuantityRate: 'Τιμή',
	productLineQuantityAmount: 'Ποσό',
	productLines: [{ ...initialProductLine }, { ...initialProductLine }],
	subTotalLabel: 'Συνολικό Ποσό',
	taxLabel: 'Φόρος (10%)',
	totalLabel: 'ΣΥΝΟΛΟ',
	currency: '€',
	notesLabel: 'Σημειώσεις',
	notes: '',
	footerText: 'ΓΕΩΡΓΙΚΗΣ ΣΧΟΛΗΣ 27 • ΤΚ: 55535 ΠΥΛΑΙΑ ΘΕΣΣΑΛΟΝΙΚΗΣ',
	termLabel: 'Όροι & Προϋποθέσεις',
	term: '',
	bankLabel: 'BANK',
	bank: '',
	ibanLabel: 'IBAN',
	iban: '',
	bicLabel: 'BIC(SWIFT)',
	bic: '',
};
