import React, { FC, useEffect, useState } from 'react';
import { initialInvoice } from '../data/initialData';
import { PDFDownloadLink, Font } from '@react-pdf/renderer';
import { Invoice } from '../data/types';
import InvoicePage from './InvoicePage';
import RobotoRegular from '../fonts/Roboto-Regular.ttf';
import RobotoBold from '../fonts/Roboto-Bold.ttf';

Font.register({
	family: 'Roboto',
	fonts: [{ src: RobotoRegular }, { src: RobotoBold, fontWeight: 600 }],
});

interface Props {
	data: Invoice;
}

const Download: FC<Props> = ({ data }) => {
	const [show, setShow] = useState<boolean>(false);

	useEffect(() => {
		setShow(false);

		const timeout = setTimeout(() => {
			setShow(true);
		}, 500);

		return () => clearTimeout(timeout);
	}, [data]);

	const onTemplateExport = () => {
		const data = window.localStorage.getItem('invoiceData');

		if (data) {
			const blob = new Blob([data], { type: 'application/json' });
			const url = URL.createObjectURL(blob);
			const link = document.createElement('a');
			link.href = url;
			link.download = 'invoice-template.json';
			link.click();
		}
	};

	const onTemplateImport = async () => {
		// create a file input
		const input = document.createElement('input');
		input.type = 'file';
		input.accept = '.json';
		// add a change event listener to the file input
		await new Promise((resolve) => {
			input.onchange = async (e) => {
				if (input.files) {
					const currentfile = input.files[0];
					let text = await currentfile.text();

					try {
						const imported_data = JSON.parse(text);

						window.localStorage.setItem('invoiceData', JSON.stringify(imported_data));
						window.location.reload();
					} catch (e) {
						alert('An error occurred!');
					}
				}
			};
			// trigger the file input click event to open the file dialog
			input.click();
		});
	};

	const onTemplateReset = () => {
		if (window.confirm('Are you sure you want to reset the invoice?')) {
			window.localStorage.setItem('invoiceData', JSON.stringify(initialInvoice));
			window.location.reload();
		}
	};

	return (
		<div className="buttons-wrapper">
			<div className={'import-template ' + (!show ? 'loading' : '')} title="Import Template" onClick={() => onTemplateImport()}>
				<span className="button-text">Import</span>
			</div>
			<div className={'export-template ' + (!show ? 'loading' : '')} title="Export Template" onClick={() => onTemplateExport()}>
				<span className="button-text">Export</span>
			</div>
			<div className={'download-pdf ' + (!show ? 'loading' : '')} title="Save PDF">
				{show && (
					<PDFDownloadLink document={<InvoicePage pdfMode={true} data={data} />} fileName={`${data.invoiceTitle ? data.invoiceTitle.toLowerCase() : 'invoice'}.pdf`} aria-label="Save PDF">
						<span className="button-text">Download</span>
					</PDFDownloadLink>
				)}
			</div>
			<div className={'reset-template ' + (!show ? 'loading' : '')} title="Reset Template" onClick={() => onTemplateReset()}>
				<span className="button-text">Reset</span>
			</div>
		</div>
	);
};

export default Download;
